@media screen and (min-width: 415px){
    .borderNone{
        border: none !important;
        box-shadow: none !important
    }
    .ui.secondary.pointing.menu{
        border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
        margin-right: 0px !important;
        font-size: 17px !important;
    }
}