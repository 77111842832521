
.paddingZero{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
}

.titleHead:hover{
    font-weight: bold !important;
}

.ui.container{
    width: 100% !important;
    margin: 0px !important;
}


.paddingLeft5{
    padding-left: 5px !important
}

.paddingLeft40{
    padding-left: 40px !important
}

.imageDiv{
    position: absolute !important;
    left: 95px !important;
    max-height: 250px !important;
    height: 250px !important;
    overflow: hidden !important;
}

.timeCheckInOut{
    height: 49px !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
    margin-left: -1px !important;
    background-color: #fff !important;
}

.paddingTopBottomRight0Left15{
    padding: 0 0 0 15px !important;
}

.paddingTop19{
    padding-top: 19px !important;
}

.paddingTop11{
    padding-top: 11px !important;
}

.outerDivClass{
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.padding10{
    padding: 10px !important;
}
.padding15{
    padding: 15px !important;
}
 
.outerDivClassForForm{
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.innerDivClassScroll{
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.maxMinHeight{
    max-height: 180px !important;
    min-height: 180px !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}
.paddingZeroRight{
    padding-right: 0px !important;
}
.paddingTopZero{
    padding-top: 0px !important;
}
.paddingBottomZero{
    padding-bottom: 0px !important;
}

.marginBottom5{
    margin-bottom: 5px !important
}

.marginTop0{
    margin-top: 0px !important
}

.userNameBold{
    font-weight: bold !important; 
}

.labelNormal{
    font-weight: normal !important; 
}

.paddingBottom0{
    padding-bottom: 0 !important
}

.paddingBottom5{
    padding-bottom: 5px !important
}

.ui.styled.accordion .content, .ui.styled.accordion .accordion .content{
    padding-right: 0 !important;
}

.bottomPadding30{
    padding-bottom: 30px !important;
}

.paddingZeroRow{
    padding: 0 !important
}

.paddingTop5Left0Bottom5Right5{
    padding: 5px 5px 5px 0 !important
}

.paddingBottom10{
    padding-bottom: 10px !important;
}

.paddingBottom15{
    padding-bottom: 15px !important;
}

.paddingRight70{
    padding-right: 70px !important
}

.paddingRight15{
    padding-right: 15px !important
}

.paddingRight30{
    padding-right: 30px !important
}

.paddingTop20{
    padding-top: 20px !important
}

.marginLeft15{
    margin-left: 15px !important
}

.marginLeft40{
    margin-left: 40px !important
}

.marginBottom15{
    margin-bottom: 15px !important
}

.paddingLeft18{
    padding-left: 18px !important
}

.paddingLeft30{
    padding-left: 30px !important
}

.paddingLeft39{
    padding-left: 39px !important
}

.paddingLeft24{
    padding-left: 24px !important
}

.paddingLeft35{
    padding-left: 35px !important
}
.paddingLeft15{
    padding-left: 15px !important
}
.paddingLeft20{
    padding-left: 20px !important
}

.paddingRight20{
    padding-right: 20px !important
}

.userCmntTime{
    padding-left: 5px;
    font-size: 9px;
}

.userInfo{
    font-size: 15px;
    font-weight: bold;
}


.textAlignLeft{
    text-align: left !important;
}

/*-------------------table----------------*/

.borderNoneObj{
    border: none !important;
}

.borderTopObj{
    border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.borderBottomObj{
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.borderBottomObjCmnt{
    border-bottom: 2px solid #dadce0 !important;
}

.border{
    border: 2px solid #dadce0 !important;
    border-radius: 5px !important
}

.borderLeft{
    border-left: 1px solid #dadce0 !important;
}


.ui.table.tbody.tr.rowColorGrey{
    background-color: #F6F7F8 !important;
}

.hideScroll {
    overflow: hidden;
}

.showYScroll {
    overflow-y: scroll !important;
}

.ui.comments .comment .text .p{
   font-weight: normal;
}

.ui.basic.label .noBorder{
 border: none;
}

.fontColorBlue {
    color : 'blue' !important ;
}

.fontColorBlack{
    color : 'black' !important ;
}

.fontColorDarkGrey{
    color : rgba(0, 0, 0, 0.6) !important ;
}

.react-datepicker__input-container .ui.form input[type="text"] {
    color: #000 !important ; 
}

.sun-editor-editable {
    font-family: Helvetica Neue,sans-serif;
    font-size: 13px;
    color: #333;
    line-height: 1.5;
    text-align: left;
    background-color: #fff;
    word-break: normal;
    word-wrap: break-word;
    padding: 10px;
    margin: 0;
}

.jobNav input[type=number]::-webkit-outer-spin-button,
.jobNav input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}