.cstmCss{
    position: fixed !important;
    bottom: 0 !important;
    width: 53% !important;
}

.cstmCssComment{
    position: fixed !important;
    bottom: 60px !important;
    width: 70% !important;
}

.bottom10{
    bottom: 10px !important
}

.paddingLeftRight70{
    padding-left: 70px !important;
    padding-right: 70px !important;
}

.paddingLeftRight40{
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.marginTopZero{
    margin-top: 5px !important;
}

.marginTopFifty{
    margin-top: 50px !important;
}

.marginTopTwenty{
    margin-top: 20px !important;
}

.marginTopTen{
    margin-top: 10px !important;
}

.marginLeftTwenty{
    margin-left: 20px !important;
}

.marginLeftZero{
    margin-left: 0 !important;
}

.width47{
    width: 47% !important
}

.ui.disabled.input {
    border: none !important ;
    background-color : transparent !important ;
    box-shadow:none ;
    opacity: 0.8 ;
    color : rgb(165,103,63);
    
}

.disabledInput {
    border: none !important ;
    background-color : transparent !important ;
    color :'black';
    
}

.paddingRight27{
    padding-right: 27px !important
}

.paddingLeftZero{
    padding-left: 0 !important
}

.paddingLeft0{
    padding-left: 0px !important
}
.paddingLeft20{
    padding-left: 20px !important
}

@media screen and (min-width: 768px){
    .paddingRight272{
        padding-right: 272px !important;
    }
}

.ql-container.ql-snow {
     height: auto;
}

.quill.newTicket .ql-container.ql-snow .ql-editor {
    height: 200px;
    overflow-y: scroll;
}

.quill.desc .ql-container.ql-snow .ql-editor {
    height: 250px;
    overflow-y: scroll;
}

.ql-editor {
    height: 100px;
    overflow-y: scroll;

}

.messageTransparent {
    border: none !important ;
    border: 0px ;
    background-color : transparent !important ;
    box-shadow:none !important;
}

.new-button {
    display: inline-block;
    padding: 8px 12px; 
    cursor: pointer;
    border-radius: 4px;
    background-color: #666666;
    font-size: 16px;
    color: #fff;
}

.new-button input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 15px;
  color: rgb(153,153,153);
}

.button-wrap {
  position: relative;
}