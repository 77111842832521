.se-resizing-bar{
    display: none !important;
}

.se-wrapper{
    bottom: 45px !important;
}

.se-toolbar{
    top: 70px !important;
}

.sun-editor{
    bottom: 10px !important;
    position: absolute !important;
}

body{
    overflow: hidden !important;
}

.sc-bdVaJa.iRVyCP.sticky-table-table{
    width: 100% !important;
}


.newStep{//link
    float: right !important;
}
.colorBlack{//link
    color: black !important;
}
    
.colorBlack:hover{
    color: #4174c9 !important;
}
.colorBlackUnderLine:hover{
    color: #4174c9 !important;
    text-decoration: underline !important;
}
.colorBlackUnderLine{
    color: black !important;
}

.sun-editor-editable p{
    margin: 0 0 5px 0 !important;
}

.se-dialog-content{
    width: 35% !important;
}
.BrainhubCarouselItem{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: fit-content !important;
    width: auto !important;
}

iframe {
    border: none!important;
}