.boxNone{
    box-shadow: none !important
}

.boxBorderRight{
    border-right: 1px solid rgba(34, 36, 38, 0.1) !important
}

.boxBorderNone{
    border: none !important
}
.boxBorderBottom{
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important
}
.boxBorderTop{
    border-top: 1px solid rgba(34, 36, 38, 0.1) !important
}


.ui.styled.accordion .title, .ui.styled.accordion .accordion .title{
    font-size:18px !important;
    font-weight: normal !important;
    padding: 12px 0px 12px 12px !important;
    margin: 4px 0px 4px 0px !important;
    color: #000 !important;
    border: none !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important
}

.ui.accordion .title, .ui.accordion .accordion .title{
    font-size:16px !important;
    font-weight: normal !important;
    margin: 2px 0px 2px 0px !important;
    color: #000 !important;
    border: none !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important
}

.marginRight13{
    margin-right: 13px !important
}
.paddingleft18{
    padding-left: 18px !important
}

.marginRightNav15{
    margin-right: -15px !important
}

.ui.accordion .active.content, .ui.accordion .accordion .active.content{
    padding-bottom: 0px !important
}

.paddingRight45{
    padding-right: 45px !important
}