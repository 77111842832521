

.ui.labeled.icon.menu .item > .icon:not(.dropdown) {
    
margin: 0.5rem auto !important; 


    
}


.ui.horizontally.fitted.menu .item, .ui.horizontally.fitted.menu .item .menu .item, .ui.menu .horizontally.fitted.item{

padding: 1.929em 9px !important;

}

.logo1{
    position: absolute !important;
    top: 25px;
    left: 30px;
    margin: 0px auto;
    width: 75%;
    height: 60px;
    cursor: pointer;

}

.logo{
    position: absolute !important;
    top: 15px;
    width: 75%;
    height: 70px;
    cursor: pointer;

}




.formheader{

padding-top: 15px !important;
font-family: 'Hind Siliguri', sans-serif !important;

}

.CustomeForm{

margin-top:26px; 

}

.Customefluid{
width:100%;
height: auto;
border-bottom: 1px solid #000; 

}

.CustomeBTN{

font-family: 'Hind Siliguri', sans-serif !important;
font-weight: 600 !important;
margin-top: 20px !important; 

}

.FontHindBold{

font-family: 'Hind Siliguri', sans-serif !important;
font-weight: 600 !important;


}

.color686868{
    color: #686868 !important;
}

.iconCstmCss{
    font-size: 16px !important;
    padding-right: 30px !important;
}

.fontSize18{
    font-weight: normal !important;
    font-size: 18px !important;
}

.homeIcon{
    height: 100% !important;
}

.paddingLeft5{
    padding-left: 5px !important;
}
.paddingLeft25{
    padding-left: 25px !important;
}

.MenuFont{

font-family: 'Hind Siliguri', sans-serif;
font-size: 13px !important; 

}

.CustomeForm .field > label{

font-family: 'Hind Siliguri', sans-serif;
font-size: 13px !important; 


}

.CustomeSegment{

padding: 1em 1em 4em !important; 

}

.HeaderSkills{
padding-top: 20px !important;
}
.paddingTop0{
padding-top: 0px !important;
}

.paddingBottom0{
padding-bottom: 0px !important;
}

.description label{

clear: both; 

}

.description textarea{

    clear: both; 
}
.react-datepicker-wrapper, .react-datepicker__input-container{


width:100%;

}

.Skillfield label{

font-size:13px;
font-weight: 600; 

}

.addRow{

visibility: hidden;
margin-right:8px !important; 

}

.Skilltable > .row:last-child .addRow, .StepsTable > .row:last-child .addRow, .collaboratorsTable > .row:last-child .addRow{

visibility: visible;

}


.EditListHeader{

font-family: 'Hind Siliguri', sans-serif !important;

}
.workflowActive, .decisionsHeader, .processesHeader{

clear:both !important;
float: left;


}

.workflowGrid, .decisionsGrid, .processesGrid{
clear:both;
float: left; 

}
.activeRow .container{
margin-bottom: 16px; 


}
.activeRowHeader, .decisionsHeader, .ProcessesGridHeader{
padding-bottom:5px !important;
font-weight: bold;
font-family: 'Hind Siliguri', sans-serif !important;

}

.boxNone > .title{
    border: none !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.rightMenuIcon .ui.dropdown > .dropdown.icon{

 margin: 0em 0em 0em 3px !important;  

}

.paddingright0{
    padding-right:0px !important
}

.head{
    font-weight: bold !important;
}
.imageClass{
    height: 100% !important;
    margin-right: 0px;
}

.paddingleft30{
    padding-left: 30px !important;
}
.paddingleft55{
    padding-left: 55px !important;
}

.padding0{
    padding: 0px !important;
}

.paddingTop5{
    padding-top: 5px !important;
}
.fontSize50{
    font-size: 50px !important;
}

.colorMenu{
    color: black !important;
}

.paddingTop10{
    padding-top: 10px !important;
}

.menuCell{
    background-color: #F6F7F8 !important;
}

.menuCellWidth{
    width: 20% !important;
}

.ui.menu .right.menu .dropdown:last-child .menu, .ui.menu .right.dropdown.item .menu, .ui.buttons > .ui.dropdown:last-child .menu{
    left: 0 !important;
}

.ui.attached.menu:not(.tabular) {
     border: 0px ; 
}

.widthclmOne{
    width: 17% !important;
}

.pointer{
    cursor: pointer !important;
}

.widthclmTwo{
    width: 81.6% !important;
}
@media screen and (max-width: 400px) and (min-width: 10px) {
    .widthclmOne{
        width: 47% !important;
    }
    .widthclmTwo{
        width: 51.6% !important;
    }
}

.paddingLeft7{
    padding-left: 7px !important
}

.borderTop{
    border-top: 1px solid rgba(34, 36, 38, 0.1) !important
}
