.buttonCstmCss{
    height: 37px !important;
    top: -11px !important;
    position: relative !important;
    text-align: center !important;
    padding: 0px 14px 0px 14px !important;
    z-index: 5;
    left: 17px !important
}

.buttonAddTodos{
    padding: 8px !important;
    padding-top: 6px !important;
    float: right !important;
}

.buttonCommentReply{
    height: 37px !important;
    top: -11px !important;
    position: relative !important;
     z-index: 5;
    text-align: center !important;
    padding: 0px 14px 0px 14px !important;
}

.buttonCommentReplyFixed{
    position: absolute !important;
     z-index: 5;
    text-align: center !important;
    padding-left: 10px !important;
    float: right !important;
    margin-right : 50px !important;
}

.buttonCstmWithIcon{
    top: 1px !important;
    position: relative !important;
    text-align: center !important;
    padding: 8px!important;
    z-index: 5;
    left: 17px !important
}

.buttonCssCstm{
    padding: 8px !important;
    padding-top: 6px !important;
    float: right !important;
    margin: 0px !important;
    margin-top: -3px !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
}
.ui.button .buttonAddRightPanel{
    padding: 6px !important;
    margin-top: 3px !important;
    float: right !important;
    margin-right: 80px;
    margin-left: 50px;
    padding-left: 17px !important;
    padding-right: 17px !important;
}

.buttonPopUp{
    padding: 8px !important;
    text-align : left !important ;
    background-color : transparent !important ;
    
}

.buttonCheckInOut{
    height: 40px !important;
    padding: 0px 21px 0px 22px !important;
    margin-right: 0px !important;
    margin-top: -6px !important;
    font-family: 'Hind Siliguri', sans-serif !important;
    
}

.buttonSubmit {
    color :'blue' !important
}

.margin0{
    margin: 0 !important
}