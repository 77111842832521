.logoDemo{
    height: 40px !important;
    width: 40px !important;
    left: 3px !important;
}

.showDoc{
    height: 600px !important;
    width: 850px !important;
}

@media screen and (min-width: 1000px) {
    .imgWidth{
        width: 250px !important;
    }
}
@media screen and (max-width: 1000px) {
    .imgWidth{
        width: 150px !important;
    }
}