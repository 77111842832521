.padding55{
    padding-left: 55px !important;
    padding-right: 55px !important
}
.paddingHeadLeftZero{
    padding-left : 0px !important;
}
.paddingLeft30{
    padding-left : 30px !important;
}
.paddingLeft20{
    padding-left : 20px !important;
}
.marginTop5{
    margin-top: 5px !important;
}
.color818284{
    color: #818284 !important;
}

.paddingRight15{
    padding-right : 15px !important
}

.paddingTop15{
    padding-top : 15px !important
}